<template>
  <KTCard
    :page="page"
    :isForm="true"
    :isEdit="isEdit"
    @submit="onSubmit"
    :isLoading="ApiIsLoading"
    :cancelTo="parentRoute"
    :readonly="finalizado"
  >
    <template #body>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label>
          Descrição <span class="text-danger">*</span>
        </template>
        <template #default>
          <b-form-input
            size="lg"
            v-model="form.descricao"
            placeholder="Digite a descrição"
            autocomplete="off"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>
      <b-form-group label-size="lg" label-cols-md="4" label-cols-lg="3">
        <template #label> Tipo <span class="text-danger">*</span> </template>
        <template #default>
          <KTSelect
            v-model="form.tipo"
            :options="form_options.tipo"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>

      <b-row>
        <b-col
          md="6"
          lg="4"
          xl="3"
          v-for="(custo, index) in form.custos"
          :key="'ano-' + custo.ano"
        >
          <b-card no-body class="p-3 mt-8">
            <p class="font-weight-bold">Ano {{ custo.ano }}</p>
            <b-form-group label-size="lg" label="Salário Mensal">
              <b-input-group :prepend="simbolo_moeda">
                <KTInputNumeric
                  v-model="form.custos[index].salario_mensal"
                  :precision="2"
                  autocomplete="off"
                  :required="!finalizado"
                  :disabled="finalizado"
                />
              </b-input-group>
            </b-form-group>
            <b-form-group
              label-size="lg"
              label="Quantidade Funcionários"
              class="mb-0"
            >
              <KTInputNumeric
                v-model="form.custos[index].quantidade_funcionarios"
                :precision="0"
                autocomplete="off"
                :required="!finalizado"
                :disabled="finalizado"
              />
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipMaoDeObraForm",
  props: {
    idplano_investimento: {
      required: true
    },
    idpip_mao_de_obra: {
      default: null
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-handshake",
        title: "Mão de Obra",
        description: `Você pode fazer o cadastro individual por funcionário
          (supervisor), por categoria (auxiliar de produção), por setor
          (almoxarifado) ou até por nome. É importante selecionar o tipo
          adequadamente. Veja mais detalhes na Ajuda (?).`
      },
      form: {
        custos: []
      },
      form_options: {
        tipo: [
          { value: 1, text: "Direta" },
          { value: 2, text: "Indireta" },
          { value: 3, text: "Administrativa" },
          { value: 4, text: "Diaristas" },
          { value: 5, text: "Outros" }
        ]
      },
      anos: 0,
      descricao_produto: "",
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/mao_de_obra`;
    },
    manual() {
      return {
        session: "mao_de_obra",
        tipo: this.tipo_plano_investimento
      };
    },
    isEdit() {
      return !!this.idpip_mao_de_obra;
    },
    parentRoute() {
      return {
        name: "pip_mao_de_obra_list",
        params: { idplano_investimento: this.idplano_investimento }
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetData(this.idpip_mao_de_obra)];

      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;

        this.simbolo_moeda = res[R_GETDATA].simbolo_moeda;
        this.anos = res[R_GETDATA].anos;
        this.descricao_produto = res[R_GETDATA].descricao_produto;
        this.form.descricao = res[R_GETDATA].descricao ?? null;
        this.form.tipo = res[R_GETDATA].tipo ?? null;

        if (res[R_GETDATA].custos && res[R_GETDATA].custos.length) {
          this.form.custos = res[R_GETDATA].custos;
        } else {
          this.form = {
            custos: []
          };
          for (let i = 1; i <= this.anos; i++) {
            this.form.custos.push({
              idpip_mao_de_obra: this.idpip_mao_de_obra,
              ano: i
            });
          }
        }
        this.tipo_plano_investimento = res[R_GETDATA].tipo_plano_investimento;
        this.finalizado = res[R_GETDATA].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    onSubmit() {
      this.ApiSubmit(this.idpip_mao_de_obra, this.form);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
